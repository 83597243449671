import React from "react"
import SEO from "../components/global/seo"
import {motion} from "framer-motion"
import {defaultVariants} from "../components/global/variants"
import '../components/404/404.scss'

const NotFoundPage = (props) => {

  return (
    <motion.main
      key={props.location.pathname}
      className="motion-page not-found"
      initial="initial"
      animate="animate"
      exit="exit"
      custom={props.oldRoute}
      variants={defaultVariants}
    >
      <SEO title="404: Not found" />
      <h1 className="not-found__headline">404</h1>
    </motion.main>
  )
}

export default NotFoundPage
